import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex)

//集中管理多个组件的共享数据
export default new Vuex.Store({
    state: {
        // responseData: null, // 响应的请求数据

        list:{
            artistId:0,//艺术家id
            title:"这是vuex初始信息",//合集标题
            description:"这是vuex初始信息",//合集描述
        }
    },
    mutations: {
        updateList(state, list) {
            state.list = list;
        }
    },
    actions: {
        updateList({ commit }, list) {
            commit('updateList', list);
        }
    }
})
