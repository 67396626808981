<template>
  <header>
    <nav class="navbar navbar-expand-md navbar-light fixed-top" id="navbar">
      <router-link class="navbarBrand" to="/">
        <img
          src="../assets/images/base/logo.png"
          width="90px"
          height="60px"
          class="d-inline-block align-top"
          alt=""
        />
        <span>SWEET UTOPIA</span>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav">
          <li class="nav-item" :class="{ active: $route.path === '/home' }">
            <router-link class="nav-link" to="/home">
              <p>{{ $t("nav.home") }}</p>
              <!-- <p>Home</p> -->
              <span class="sr-only">(current)</span>
            </router-link>
          </li>
          <li class="nav-item" :class="{ active: $route.path === '/about' }">
            <router-link class="nav-link" to="/about">
              <p>{{ $t("nav.about") }}</p>
              <!-- <p>About</p> -->
              <span class="sr-only">(current)</span>
            </router-link>
          </li>
          <li class="nav-item" :class="{ active: $route.path === '/artist' }">
            <router-link class="nav-link" to="/artist">
              <p>{{ $t("nav.artists") }}</p>
              <!-- <p>Artists</p> -->
              <span class="sr-only">(current)</span>
            </router-link>
          </li>
          <li class="nav-item" :class="{ active: $route.path === '/project' }">
            <router-link class="nav-link" to="/project">
              <p>{{ $t("nav.projects") }}</p>
              <!-- <p>Projects</p> -->
              <span class="sr-only">(current)</span>
            </router-link>
          </li>
          <li class="nav-item" :class="{ active: $route.path === '/resident' }">
            <router-link class="nav-link" to="/resident">
              <p>{{ $t("nav.plan") }}</p>
              <!-- <p>Resident Plans</p> -->
              <span class="sr-only">(current)</span>
            </router-link>
          </li>
          <li class="nav-item" :class="{ active: $route.path === '/contact' }">
            <router-link class="nav-link" to="/contact">
              <p>{{ $t("nav.contact") }}</p>
              <!-- <p>Contact</p> -->
              <span class="sr-only">(current)</span>
            </router-link>
          </li>
        </ul>
        <div>
          <!-- <span>语言：</span><span>中文</span>/<span>english</span> -->
          <!-- <button style="font-size: 10px" @click="changeLang">语言切换</button> -->
          <el-select id="el-select" v-model="value" placeholder="请选择" @change="changeLang" size="mini" style="width: 100px;border: none;">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </nav>
  </header>
</template>

<script scoped>
import $ from "jquery";
import axios from "axios";
export default {
  data() {
    return {
      options: [
        {
          value: "zh",
          label: "中文",
        },
        {
          value: "en",
          label: "english",
        },
      ],
      value: this.$i18n.locale,
    };
  },
  mounted() {
    this.getLocale();

    let lastScrollTop = 0;
    let navbarHeight = document.getElementById("navbar").offsetHeight;

    window.addEventListener("scroll", () => {
      let currentScroll =
        window.pageYOffset || document.documentElement.scrollTop;

      let button = document.querySelector(".navbar-toggler");
      let ariaExpandedValue = button.getAttribute("aria-expanded");
      let isNavbarExpanded = ariaExpandedValue === "true";
      let navbar = document.getElementById("navbar");
      if (isNavbarExpanded) {
        // 如果导航栏展开，则向下滑动页面时收回导航栏
        if (currentScroll > lastScrollTop) {
          this.hideNavbarCollapse();
        }
      } else {
        if (currentScroll > lastScrollTop) {
          if (currentScroll > navbarHeight) {
            this.hideNavbar(navbar);
          }
        } else {
          this.showNavbar(navbar);
        }
      }

      lastScrollTop = currentScroll;
    });

    document.addEventListener("click", (event) => {
      const target = event.target;
      const isNavbarToggler = target.classList.contains("navbar-toggler");
      const isNavbarContent = target.closest(".navbar-collapse");

      if (!isNavbarToggler && !isNavbarContent) {
        this.hideNavbarCollapse();
      }
    });

    const navLinks = document.querySelectorAll(".navbar-nav .nav-link");
    navLinks.forEach((link) => {
      link.addEventListener("click", () => {
        this.hideNavbarCollapse();
      });
    });
  },
  methods: {
    getLocale() {
      const locale = navigator.language;
      console.log(locale);

      const selectedLanguage = localStorage.getItem("locale");
      console.log("本地语言：" + selectedLanguage);
    },
    changeLang() {
      //刷新当前页面
      this.$router.go(0);

      if (this.value === "en") {
        this.$i18n.locale = "en";
        localStorage.setItem("locale", "en");
      } else {
        this.$i18n.locale = "zh";
        localStorage.setItem("locale", "zh");
      }

      // 将语言信息应用到每个请求头的 Accept-Language
      const langHeader = this.$i18n.locale === "en" ? "en-US" : "zh-CN";
      axios.defaults.headers.common["Accept-Language"] = langHeader;
    },

    hideNavbarCollapse() {
      const navbarCollapse = document.querySelector(".navbar-collapse");
      if (navbarCollapse.classList.contains("show")) {
        $(".navbar-collapse").collapse("hide");
      }
    },
    showNavbar(navbar) {
      navbar.classList.remove("navbar-hide");
      navbar.classList.add("navbar-show");
    },
    hideNavbar(navbar) {
      navbar.classList.remove("navbar-show");
      navbar.classList.add("navbar-hide");
    },
  },
};
</script>

<style scoped>


#el-select{
  border: none !important;
}

/* 导航栏开始 */

#navbarCollapse .router-link-active {
  color: #069;
}
.navbar {
  background-color: rgb(255, 255, 255);
  /* opacity: 0.9; */
  transition: top 0.6s;
  /* border-bottom: black 2px solid; */
}

.navbarBrand {
  /* 转换动画 */
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: black;
  /* justify-content: space-between; */
  /* /* margin-right: 10px; */
  text-decoration: none;
}

.navbarBrand img {
  height: 60px;
  width: 90px;
  margin-left: 20px;
  margin-right: 20px;
}

#navbarCollapse {
  justify-content: flex-end;
  text-align: center;
}

#navbarCollapse P {
  margin: 0 auto;
}

.navbar-hide {
  top: -100px;
}

.navbar-show {
  top: 0;
}
.nav-link p:first-of-type {
  font-weight: 600;
}

/* 导航栏结束 */

/* PC端开始 */
@media screen and (min-width: 992px) {
}

/* PC端结束 */
/* iPad端开始 */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .navbarBrand {
    max-width: 30%;
  }

  .navbarBrand img {
    height: 40px;
    width: 60px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .navbarBrand span {
    font-size: 14px;
  }
}

/* iPad端结束 */
/* 手机端开始 */
@media screen and (max-width: 767px) {
  nav {
    background-color: #fff;
  }

  .navbarBrand img {
    height: 33.333px;
    width: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .navbarBrand span {
    font-size: 14px;
  }
}

/* 手机端结束 */
</style>