<template>
  <div>
    <su-header />
    <main>
      <router-view />
    </main>
    <back-to-top />
    <su-footer />
  </div>
</template>

<script>
import "jquery/dist/jquery";
import "bootstrap/dist/js/bootstrap.bundle";
import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/style/demo.css";
import SuHeader from "@/components/SuHeader.vue";
import SuFooter from "@/components/SuFooter.vue";
import BackToTop from "@/components/BackToTop.vue";
export default {
  components: { SuHeader, SuFooter, BackToTop },
 
  mounted() {
    this.listenAnimated();
  },

  methods: {
    //监听事件，添加animate动画
    listenAnimated() {
      const boxes = document.querySelectorAll(".animate__animated");
      window.addEventListener("scroll", () => {
        boxes.forEach((box) => {
          if (this.isElementInViewport(box)) {
            // 在滚动到盒子时为其添加一个class名字
            box.classList.add("animate__fadeInUp");
          }
        });
      });
    },

    // 检查元素是否在视口中
    isElementInViewport(element) {
      const rect = element.getBoundingClientRect();
      return rect.top >= 0 && rect.top <= window.innerHeight;
    },
  },
};
</script>

<style>
@import "bootstrap-icons";
* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}

body {
  background-color: #fafbfc;
  color: gray;
}
main {
  margin-top: 76px;
  min-height: 80vh;
}

P {
  display: block;
}

a {
  text-decoration: none;
}

a:hover {
  color: black;
  text-decoration: none;
}

.pt-5,
.py-5 {
  padding-top: 3rem;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem;
}

.bg-tertiary {
  background-color: #192b3f;
}

h1,
.h1 {
  font-size: 2.1875rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.3;
  color: #343a40;
}
.border {
  border: 1px solid black;
}
/* PC端开始 */
@media screen and (min-width: 992px) {
  h1 {
    font-size: 40px;
  }
  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 26px;
  }
}

/* PC端结束 */
/* iPad端开始 */
@media screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 30px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 26px;
  }
  main {
    margin-top: 60px;
  }
}

/* iPad端结束 */
/* 手机端开始 */
@media screen and (max-width: 767px) {
  h1 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  main {
    margin-top: 55.60px;
  }
}

/* 手机端结束 */
</style>