<template>
  <section class="banner" ref="container" :style="{ height: containerHeight }">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'SuBanner',
  data() {
    return {
      x: 20, // 设置比例 x，例如 50 表示高度等于宽度的一半
      xm: 30,
      xs: 40,
      containerHeight: '', // 导出的容器高度
    };
  },
  computed: {
    paddingBottom() {
      return `${this.x}%`;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateHeight);
    this.updateHeight();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateHeight);
  },
  methods: {
    //定义banner高度
    updateHeight() {
      let containerWidth = this.$refs.container.offsetWidth;
      let contentHeight = 0;
      if (containerWidth > 991) {
        contentHeight = (containerWidth * this.x) / 100;
      } else if (containerWidth > 767) {
        contentHeight = (containerWidth * this.xm) / 100;
      } else {
        contentHeight = (containerWidth * this.xs) / 100;
      }
      // this.$refs.container.style.height = `${contentHeight}px`;
      this.containerHeight = `${contentHeight}px`; // 更新容器高度
      this.$emit('container-height-updated', contentHeight); // 触发自定义事件，将容器高度传递给父组件
    },
  },
};
</script>

<style scoped>
/* 横幅开始 */
.banner {
  position: relative;
  width: 100%;
  background-color: lightgray;
}
.banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.banner img {
  width: 100%;
  height: 100%;

  border: 0;
  margin: 0;
}
.location {
  position: absolute;
  bottom: 0;
}
/* 横幅结束 */
</style>