export default{
    nav:{
        home:"HOMEPAGE",
        about:"ABOUT US",
        artists:'ARTISTS',
        projects:'PROJECTS',
        plan:'RESIDENT PLANS',
        contact:'CONTACT'
    },
    footer:{
        copyright:'',
        ttb:'SWEET UTOPIA ART'
    },
    about:{
        comIntro:'Company Profile',
        exhiRev:'Exhibition Review',
        comIntroText:'Tiantuobang is a small art planning and management agency established in 2021, providing exhibition planning, artwork agency sales, artist agency and other consulting services for art authors and brands at home and abroad. Tiantuobang means Sweet Ideal Country. We do not limit the types of culture or forms of works. We respect, encourage, and support original works of expression, emphasizing a sustained and full passion for life. We hope that art is not limited to its genre and past experiences, but can create more wild/non-traditional/experimental content to fill the missing part of the "nature of the soul" in contemporary life due to artistic homogenization.',
    },
    artist:{
        copArtist:'Collaborating Artists',
        exp:'Experience',
        works:'Works',
        reward:'Awards',
        member:'Members',
        readMore:'read more',
        exclusive:'Exclusive Artist Of Sweet Utopia',
        cooperation1:'Collaboration',
        cooperation2:'With Chinese Artists'
    },
    project:{
        proj:'Project',
        definition:'What Is It',
        hope:'Its Artistic Vision'
    },
    contact:{
        address:'Address',
        contact:'Contact',
        subscribe:'Subscribe',
        copy:'copy success',
        address1:'',

    },
    page:{
        title:'Sweet Utopia Art'
    }
}