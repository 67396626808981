<template>
  <footer class="pt-5 pb-5 footer footer-dark bg-tertiary">
    <h1 style="text-align: center"><span>{{ $t('footer.copyright') }}</span>@copyright {{ $t('footer.ttb') }}</h1>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
/* footer开始 */
footer div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* 水平居中 */
}

footer {
  background: #fff;
  background-color: rgb(255, 255, 255);
  color: #6c757d;
}

.pt-5,
.py-5 {
  padding-top: 3rem;
}
.pt-3,
.py-3 {
  padding-top: 1rem;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem;
}
.bg-tertiary {
  background-color: #192b3f;
}

h1,
.h1 {
  font-size: 2.1875rem;
  
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: initial;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.3;
  color: #343a40;
}

/* footer结束 */
@media screen and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 1rem;

  }
  .pt-5,
.py-5 {
  padding-top: 2rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 2rem !important;
}
}
</style>