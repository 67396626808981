import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/container/ContainerView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: HomeView,
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: () => import('../views/home/HomeView.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/about/AboutView.vue')
      }
      ,
      {
        path: '/artist',
        name: 'artist',
        component: () => import('../views/artists/ArtistsView.vue')
      }
      ,
      {
        path: '/project',
        name: 'project',
        component: () => import('../views/project/ProjectView.vue')
      }
      ,
      {
        path: '/resident',
        name: 'resident',
        component: () => import('../views/resident/ResidentView.vue')
      }
      ,
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/contact/ContactView.vue')
      },
      {
        path: '/artist/:id',
        name: 'ArtistDetails',
        component: () => import('../views/artists/DetailView.vue')
      },
      {
        path: '/artist/list/:listId',
        name: 'list',
        component: () => import('../views/artists/CreationView.vue')
      },
      {
        path: '/news/:id',
        name: 'newsDetail',
        component: () => import('../views/news/NewsDetail.vue')
      },
      {
        path: '/about/:id',
        name: 'aboutDetail',
        component: () => import('../views/news/NewsDetail.vue')
      }

    ]

  },
  {
    path: '/404',
    component: () => import('../views/404View.vue')
  }
  , {
    path: '*',
    redirect: '/404'
  }
]



const router = new VueRouter({
  routes,
  // 对于页面跳转，全部都返回到页面顶部。
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  transition: false
})
router.beforeEach((to, from,next) => {
  // 获取当前语言
  const lang = localStorage.getItem('locale') || 'en'; // 假设你的语言存储在localStorage中

  // 设置相应的标题翻译
  if (lang === 'en') {
    document.title = to.meta.titleEn || 'SWEET UTOPIA AGENCY';
  } else if (lang === 'zh') {
    document.title = to.meta.titleZh || '甜托邦——艺术策划经纪机构，为海内外艺术作者和艺术品牌提供展览策划、作品代理销售、艺术家经纪代理等咨询服务';
  } else {
    document.title = 'Default Title';
  }

  next();
});
export default router
