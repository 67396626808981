import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store';
import SuBanner from "@/components/SuBanner.vue";
import BackIcon from "@/components/BackIcon.vue"; //返回按钮
// import VueI18n from 'vue-i18n'
import i18n from './lang/index'

// Vue.use(VueI18n)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.component('SuBanner',SuBanner)
Vue.component('BackIcon',BackIcon)

// const i18n = new VueI18n({
//   locale:"zh",
//   messages:{
//     en:{
//       test:"test"
//     },
//     zh:{
//       test:"测试"
//     }
//   }
// })

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
