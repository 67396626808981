<template>
  <a id="back-to-top" v-show="showBackToTop">
    <i class="bi bi-arrow-up-circle-fill" style="color: #069"> </i>
  </a>
</template>

<script>
export default {
  data() {
    return {
      showBackToTop: false,
    };
  },
  mounted() {
    window.onscroll = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        this.showBackToTop = true;
      } else {
        this.showBackToTop = false;
      }
    };

    // 点击按钮返回页面顶部
    document.getElementById("back-to-top").onclick = function () {
      document.body.scrollTop = 0; // 兼容旧版浏览器
      document.documentElement.scrollTop = 0; // 兼容现代浏览器
    };
  },
};
</script>

<style scoped>
/* 返回顶部 */
#back-to-top {
  /* display: none; */
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 99;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  font-size: 34px;
  line-height: 34px;
  /* opacity: 0.5; */
}

#back-to-top:hover {
  opacity: 1;
}
/* 返回顶部结束 */

/* 手机端开始 */
@media screen and (max-width: 767px) {
  #back-to-top {
    bottom: 20px;
    right: 10px;
  }
}

/* 手机端结束 */
</style>