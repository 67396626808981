// import Vue from 'vue'
// import VueI18n from 'vue-i18n'
// import enLocale from './en'
// import zhLocale from './zh'

// Vue.use(VueI18n)

// const messages = {
//     en: {
//         ...enLocale
//     },
//     zh: {
//         ...zhLocale
//     }
// }
// // 映射对象，将navigator.language的值映射到网站支持的locale
// const localeMapping = {
//     'en': 'en-US',
//     'zh': 'zh'
//     // 更多映射...
// };

// // 获取映射后的locale值
// function getMappedLocale(language) {
//     const normalizedLanguage = language.toLowerCase().substring(0, 2);
//     return localeMapping[normalizedLanguage] || 'en'; // 默认为英文
// }

// const i18n = new VueI18n({
//     locale : getMappedLocale(navigator.language),
//     messages
// })

// export default i18n
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from './en';
import zhLocale from './zh';
import axios from "axios";

Vue.use(VueI18n);

const messages = {
  en: {
    ...enLocale
  },
  zh: {
    ...zhLocale
  }
};

const localeMapping = {
  'en': 'en-US',
  'zh': 'zh'
  // 更多映射...
};

function getMappedLocale(language) {
  const normalizedLanguage = language.toLowerCase().substring(0, 2);
  return localeMapping[normalizedLanguage] || 'en';
}

// 从 localStorage 获取 locale 值
const storedLocale = localStorage.getItem('locale');
const initialLocale = storedLocale || getMappedLocale(navigator.language);


// 将语言信息应用到每个请求头的 Accept-Language
const langHeader = initialLocale === "en" ? "en-US" : "zh-CN";
axios.defaults.headers.common["Accept-Language"] = langHeader;

const i18n = new VueI18n({
  locale: initialLocale,
  messages,
  dateTimeFormats:{
    'en': {
      short: {
        year: 'numeric', month: 'short', day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric'
      }
    },
      'zh':{
        short: {
          year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          weekday: 'long',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        }
      }
}
});

// 监听 locale 变化，将其保存到 localStorage
// i18n.locale = initialLocale;
// i18n.watch.locale = function(newLocale) {
//   localStorage.setItem('locale', newLocale);
// };

export default i18n;