export default{
    nav:{
        home:"首页",
        about:"公司简介",
        artists:'合作艺术家',
        projects:'海外项目',
        plan:'驻场计划',
        contact:'联系我们'
    },
    footer:{
        copyright:'版权所有',
        ttb:'甜托邦'
    },
    about:{
        comIntro:'公司简介',
        exhiRev:'展览回顾',
        comIntroText:'甜托邦是一个成立2021年的小型艺术策划经纪机构， 为海内外艺术作者和艺术品牌提供展览策划、作品代理销售、 艺术家经纪代理等咨询服务。甜托邦意为甜蜜理想国，我们不限定文化的种类、作品的形式，我们尊重、鼓励、支持原创的作品表达， 强调对待生活持续而饱满的热情。我们希望艺术不局限于它的体裁形式和既往经验，而能有更多野生/非传统/实验性的内容开创出来， 以填补当代生活中因艺术同质化而缺失的那部分“心灵的自然"。'
    },
    artist:{
        copArtist:'合作艺术家',
        exp:'参展经历',
        works:'艺术家作品',
        reward:'获奖经历',
        member:'团队成员',
        readMore:'查看更多',
        exclusive:'甜托邦独家签约艺术家',
        cooperation1:'合作中国',
        cooperation2:'艺术家部分'
    },
    project:{
        proj:'海外项目',
        definition:'何为...',
        hope:'...的艺术愿景'
    },
    contact:{
        address:'公司地址',
        contact:'联系我们',
        subscribe:'关注我们',
        copy:'复制成功',
        address1:'地址',

    },
    test2:"测试2"
}